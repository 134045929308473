import { Fragment, useState } from 'react';
import SubTitle from "../Components/SubTitle";

import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'

import Biz1 from "../IMG/OurBiz/Our_Biz2.png";
import Biz2 from "../IMG/OurBiz/Our_Partner.png";

import styles from './styles/About.module.scss';
const Business = () => {

    const years = ['2021','2022','2023']

    const [selectedYear,setSelectedYear] = useState('2022');

    const [yearsContent,] = useState({
        '2025': [

        ],
        '2024': [
            
        ],
        '2023': [

        ],
        '2022': [
            {
                name:"5월",
                value : [
                    {
                        item: "벤처기업확인(벤처투자유형)"
                    },
                    {
                        item: "기업부설연구소 인정(한국산업기술진흥협외)"
                    }
                ]
            }
        ],

        '2021': [
            {
                name:"12월",
                value : [
                    {
                        item: "연구소 기업 등록(연구개발특구진흥재단)"
                    }
                ]
            },
            {
                name:"11월",
                value : [
                    {
                        item: "ETRI 현물출자"
                    }
                ]
            },
            {
                name:"10월",
                value : [
                    {
                        item: "엠디하이 주식회사 설립(정재연 대표이사 취임)",
                    },
                    {
                        item: "ETRI HOLDINGS 대아정보시스템 출자"
                    }
                ]
            },
            {
                name:"9월",
                value : [
                    {
                        item: "투자심의위원회 (ETRI HOLDINGS)"
                    },
                    {
                        item: "창업심의위원회 (ETRI)"
                    }
                ]
            },
            {
                name:"4월" ,
                value: [
                    {
                        item: "사업화 심의 (ETRI)"
                    }
                ]
            },
            {
                name:"3월",
                value : [
                    {
                        item: "기술사업화 신청서 접수(DAEA INFOSYS-ETRI HOLDINGS)"
                    }
                ]
            }
        ], 
    });

    const getDefaultRem = () => {
        if(window.innerWidth>1000) {
            const rem = years.length>3&&years.length%2===0 ? -5 :
            years.length>3&&years.length%2===1 ? -10 :
            years.length%2===0 ? 5 : 0;
            return rem
        } else {
            const rem = years.length>3&&years.length%2===0 ? -2 :
            years.length>3&&years.length%2===1 ? -4 :
            years.length%2===0 ? 2 : 0;
            return rem
        }
    };

    //TODO: 모바일일 때 rem 2,4 추가 [23/02/10 완료]
    const [transformVal, setTransformVal] = useState(
        getDefaultRem()
        //years.length%2==0 ? 5 : -10
    );
    const [extraTransform,setExtraTransform] = useState(0);

    const onClick = async (type,diff) => {

        const rem = window.innerWidth<1000 ? 4 : 10;

        const idx = years.indexOf(selectedYear);

        // const n_idx = idx+1;

        const len = years.length;
        // const len_diff = len-n_idx
        const isEven = len%2===0;      
        // if(
        //     (len>3) && ((idx===0) || (len_diff>0))
        // ) setExtraTransform(10);
        // else if(
        //     (len>3) && ((idx===len-1) || (len_diff<0))
        // ) setExtraTransform(10);

        if(diff) {
            if(diff>0) {
                if(type==="backward") { 
                    setSelectedYear(years[idx-1])
                    setTransformVal(transformVal - (rem*diff))
                }
                if(type==="forward") {
                    setSelectedYear(years[idx+1])
                    setTransformVal(transformVal + (rem*diff))
                }
            } else {
                if(type==="backward") { 
                    setSelectedYear(years[idx-1])
                    setTransformVal(transformVal + (rem*diff))
                }
                if(type==="forward") {
                    setSelectedYear(years[idx+1])
                    setTransformVal(transformVal - (rem*diff))
                }
            }
            
        }
        else {
            //console.log(Math.ceil((len-1)/2))
            if(type==="backward") { 
                setSelectedYear(years[idx-1])
                setTransformVal(transformVal - rem)
                if(
                    len>3 &&
                    (idx===1 || idx<=Math.ceil((len-1)/2)) && !isEven
                ) {
                    setExtraTransform(rem)
                } else if(
                    len>3 && (idx===1 || idx<=Math.ceil((len-1)/2)) && isEven
                ) setExtraTransform(rem)
            }
            if(type==="forward") {
                setSelectedYear(years[idx+1])
                setTransformVal(transformVal + rem)
                if(
                    len>3 &&
                    (idx===len-2 || idx>=Math.ceil((len-1)/2)) && !isEven
                ) {
                    setExtraTransform(-rem)
                } else if(
                    len>3 && (idx===1 || idx>=Math.ceil((len-1)/2)) && isEven
                ) setExtraTransform(-rem)
            }
        }
    };

    const onYearClick = (year) => {
       
        const og_idx = years.indexOf(selectedYear)
        const nu_idx = years.indexOf(year)
        const diff = og_idx - nu_idx;
        //console.log(og_idx, nu_idx)
        if(og_idx>nu_idx) {
            onClick("backward",diff)
            setSelectedYear(year)
        }
        if(og_idx<nu_idx) {
            onClick("forward",diff)
            setSelectedYear(year)
        }
        else return;
    };

    return(
        <Fragment>
            <SubTitle loc="Our Business"/>
            <div className={styles.wrapper} style={{paddingTop:'3rem'}}>

                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>Business Summray</span>
                    </div>

                    <div className={styles.imageContent}>
                        <img style={{minWidth:'60%'}} src={Biz1} alt="_imageGlance"/>
                    </div>
                    
                </div>

                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>Footprints</span>
                    </div>

                    <div className={styles.tabContent}>
                        <div className={styles.tabMenu}>
                            <span 
                                className={[styles.tabIcons, years.indexOf(selectedYear)===0 && styles._disabled].join(" ")}                             
                                onClick={()=>onClick("backward")}
                            ><MdArrowBackIos/></span>
                            <div className={styles.tabPseudo}>
                                <div 
                                    className={styles.pseudoWrap}
                                >
                                    <span 
                                        className={styles.indicator}
                                        style={{transform:
                                            `translateX(${transformVal+extraTransform}rem)`
                                            // (years.indexOf(selectedYear)!==0 ||
                                            // years.indexOf(selectedYear)!==years.length-1) ?
                                            // `translateX(${transformVal+extraTransform}rem)`:
                                            // `translateX(${transformVal}rem)`
                                        }}
                                    ></span>
                                    <span
                                        className={styles.indicatorBar}
                                        style={{transform:
                                            `translateX(${transformVal+extraTransform}rem)`
                                            // (years.indexOf(selectedYear)!==0 ||
                                            // years.indexOf(selectedYear)!==years.length-1) ?
                                            // `translateX(${transformVal+extraTransform}rem)`:
                                            // `translateX(${transformVal}rem)`
                                        }}
                                    ></span>
                                {years.map((v,i)=>{return(
                                    <span 
                                        className={[
                                            styles.tabBtn
                                        ].join(" ")}
                                        name={v}
                                        onClick={(e)=>onYearClick(v)}
                                        style={{
                                            color:v===selectedYear ? 'black' : 'grey',
                                            transform: 
                                                `translateX(${extraTransform}rem)`
                                                // (years.indexOf(selectedYear)!==0 ||
                                                // years.indexOf(selectedYear)!==years.length-1)
                                                // &&
                                                // `translateX(${extraTransform}rem)`
                
                                        }}
                                        key={`year_${i}th`}>{v}</span>
                                        
                                )})}
                                </div>
                            </div>
                            <span 
                                className={[styles.tabIcons, years.indexOf(selectedYear)===years.length-1 && styles._disabled].join(" ")}
                                onClick={()=>onClick("forward")}
                            ><MdArrowForwardIos/></span>
                        </div>

                        <div className={styles.tabTimeline}>
                            {/* {selectedYear && yearsContent[selectedYear].map((v,i)=>{return(
                            <div className={[styles.timelineContainer, i%2===0 ? styles._toRight : styles._toLeft].join(" ")} key={`${selectedYear}_${v.name}_${i}`}>
                                <span className={i%2===1 ? styles.timeMonth : styles.timeDesc}>
                                    {i%2===1 ?
                                    v.name:
                                    v.value.map((val,idx)=>{return(
                                    <p key={`${v.name}_${val.item}_${idx}}`}>{val.item}</p>
                                    )})
                                    }
                                    
                                </span>
                                <span className={i%2===1 ? styles.timeDesc : styles.timeMonth}>
                                    {i%2===1 ?
                                    v.value.map((val,idx)=>{return(
                                    <p key={`${v.name}_${val.item}_${idx}}`}>{val.item}</p>
                                    )}):
                                    v.name
                                    }
                                </span>
                            </div>
                            )})} */}
                            {selectedYear && yearsContent[selectedYear].map((v,i)=>{return(
                            <div className={styles.timelineWrap} key={`timeline_${v.name}_${i}`}>
                                <span 
                                    className={styles.timelineMonth}
                                >
                                    {v.name}
                                </span>

                                <span className={styles.timelineDesc}>
                                    {v.value.map((val,idx)=>{return(<span key={`timeline_${v.name}_${idx}`}>{val.item}</span>)})}
                                </span>
                            </div>
                            )})}
                            {selectedYear && yearsContent[selectedYear].length===0 &&
                            <div style={{color:'grey'}}>해당 년도의 데이터가 없습니다.</div>
                            }
                        </div>
                    </div>
                    
                </div>

                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>Our Partner</span>
                    </div>

                    <div className={styles.imageContent}>
                        <img style={{minWidth:'80%'}} src={Biz2} alt="_imageGlance"/>
                    </div>
                    
                </div>

            </div>
        </Fragment>
    )
}

export default Business;