import Signature from "../IMG/Main/Signature.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {GiHamburgerMenu} from "react-icons/gi"
import {BiArrowToTop} from "react-icons/bi"


const Header = () => {
  let Location = useLocation();

  useEffect(() => {}, [Location]);

  
  const [isClickedMenu, setIsClickedMenu] = useState(false);
  const [showButton,setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scroll({
        top: 0,
        // behavior: 'smooth'
        behavior: 'auto'
    })

}

useEffect(() => {
  const handleShowButton = () => {
      if (window.scrollY > 200) {
          setShowButton(true)
      } else {
          setShowButton(false)
      }
  }

  // console.log(window.scrollY)
  window.addEventListener("scroll", handleShowButton)
  return () => {
      window.removeEventListener("scroll", handleShowButton)
  }
}, [])
  
  return (
    <header className="headerTag">
      <div className="Header">
        <div className="Signature_blank-wrap">
          <div className="Signature-wrap"           onClick={()=>{setIsClickedMenu(false)}}>
            <Link to="/">
              <img src={Signature} alt="logo"></img>
            </Link>
          </div>

          <div
            className="mobile-menu-btn"
            onClick={() => {
              setIsClickedMenu(!isClickedMenu);
            }}
          >
           <GiHamburgerMenu/>
          </div>
          {isClickedMenu===true?
          <div className="mobile-menu-list" >
<hr></hr>
          <div className="mobile-About"          onClick={()=>{setIsClickedMenu(false)}}>
            <Link to="/about">
              <div
                className={
                  Location.pathname === "/about"
                    ? "AboutTextClicked"
                    : "AboutText"
                }
              >
                About MDHi
              </div>
            </Link>
          </div>
          <hr></hr>
          <div className="mobile-Biz"          onClick={()=>{setIsClickedMenu(false)}}>
            <Link to="/biz">
              <div
                className={
                  Location.pathname === "/biz" ? "BizTextClicked" : "BizText"
                }
              >
                Our Business
              </div>
            </Link>
            <hr></hr>
          </div>
          <div className="mobile-Rnd"          onClick={()=>{setIsClickedMenu(false)}}>
            <Link to="/rnd">
              <div
                className={
                  Location.pathname === "/rnd" ? "RndTextClicked" : "RndText"
                }
              >
                R&D
              </div>
            </Link>
            <hr></hr>
          </div>
          <div className="mobile-Career"          onClick={()=>{setIsClickedMenu(false)}}>
            <Link to="/career">
              <div
                className={
                  Location.pathname === "/career"
                    ? "CareerTextClicked"
                    : "CareerText"
                }
              >
                Career
              </div>
            </Link>
            <hr></hr>
          </div>
          <div className="mobile-Contact"          onClick={()=>{setIsClickedMenu(false)}}>
            <Link to="/contact">
              <div
                className={
                  Location.pathname === "/contact"
                    ? "ContactTextClicked"
                    : "ContactText"
                }
              >
                Contact Us
              </div>
            </Link>
            <hr></hr>

          </div>
        </div>:<></>}
          
        </div>
{/* 모바일 버튼 끝 */}

{/* To-top-button */}
{showButton && (
        <div className="scroll-container" 
        onClick={scrollToTop}>
          <BiArrowToTop size='50px' color='white' ></BiArrowToTop>
        </div>

    )}



        {/**선택한 페이지로 이동 */}
        <div className="Menu-wrap">
          <div className="About">
            <Link to="/about">
              <div
                className={
                  Location.pathname === "/about"
                    ? "AboutTextClicked"
                    : "AboutText"
                }
              >
                About MDHi
              </div>
            </Link>
          </div>
          <div className="Biz">
            <Link to="/biz">
              <div
                className={
                  Location.pathname === "/biz" ? "BizTextClicked" : "BizText"
                }
              >
                Our Business
              </div>
            </Link>
          </div>
          <div className="Rnd">
            <Link to="/rnd">
              <div
                className={
                  Location.pathname === "/rnd" ? "RndTextClicked" : "RndText"
                }
              >
                R&D
              </div>
            </Link>
          </div>
          <div className="Career">
            <Link to="/career">
              <div
                className={
                  Location.pathname === "/career"
                    ? "CareerTextClicked"
                    : "CareerText"
                }
              >
                Career
              </div>
            </Link>
          </div>
          <div className="Contact">
            <Link to="/contact">
              <div
                className={
                  Location.pathname === "/contact"
                    ? "ContactTextClicked"
                    : "ContactText"
                }
              >
                Contact Us
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
