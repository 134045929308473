import styles from "./styles/About.module.scss";

import biz1w from "../IMG/Main/Our Business/1_RD.webp";
import biz1a1 from "../IMG/Main/Our Business/1_R_D_uflriw/1_R&D_256w.avif";
import biz1a2 from "../IMG/Main/Our Business/1_R_D_uflriw/1_R&D_709w.avif";
import biz1a3 from "../IMG/Main/Our Business/1_R_D_uflriw/1_R&D_1007w.avif";
import biz1a4 from "../IMG/Main/Our Business/1_R_D_uflriw/1_R&D_1546w.avif";
import biz1a5 from "../IMG/Main/Our Business/1_R_D_uflriw/1_R&D_1960w.avif";
import biz1j1 from "../IMG/Main/Our Business/1_R_D_jpg/1_R&D_256w.jpg"
import biz1j2 from "../IMG/Main/Our Business/1_R_D_jpg/1_R&D_703w.jpg"
import biz1j3 from "../IMG/Main/Our Business/1_R_D_jpg/1_R&D_1074w.jpg"
import biz1j4 from "../IMG/Main/Our Business/1_R_D_jpg/1_R&D_1545w.jpg"
import biz1j5 from "../IMG/Main/Our Business/1_R_D_jpg/1_R&D_1960w.jpg"


import biz2w from "../IMG/Main/Our Business/2_EMR.webp";
import biz2a1 from "../IMG/Main/Our Business/2_EMR_vuixdv/2_EMR_256w.avif";
import biz2a2 from "../IMG/Main/Our Business/2_EMR_vuixdv/2_EMR_908w.avif";
import biz2a3 from "../IMG/Main/Our Business/2_EMR_vuixdv/2_EMR_1574w.avif";
import biz2a4 from "../IMG/Main/Our Business/2_EMR_vuixdv/2_EMR_1960w.avif";
import biz2j1 from "../IMG/Main/Our Business/2_EMR_jpg/2_EMR_256w.jpg"
import biz2j2 from "../IMG/Main/Our Business/2_EMR_jpg/2_EMR_857w.jpg"
import biz2j3 from "../IMG/Main/Our Business/2_EMR_jpg/2_EMR_1594w.jpg"
import biz2j4 from "../IMG/Main/Our Business/2_EMR_jpg/2_EMR_1960w.jpg"

import biz3w from "../IMG/Main/Our Business/3_PHM.webp";
import biz3a1 from "../IMG/Main/Our Business/3_PHM_pa0oun/3_PHM_256w.avif";
import biz3a2 from "../IMG/Main/Our Business/3_PHM_pa0oun/3_PHM_1013w.avif";
import biz3a3 from "../IMG/Main/Our Business/3_PHM_pa0oun/3_PHM_1515w.avif";
import biz3a4 from "../IMG/Main/Our Business/3_PHM_pa0oun/3_PHM_1960w.avif";
import biz3j1 from "../IMG/Main/Our Business/3_PHM_jpg/3_PHM_256w.jpg";
import biz3j2 from "../IMG/Main/Our Business/3_PHM_jpg/3_PHM_863w.jpg";
import biz3j3 from "../IMG/Main/Our Business/3_PHM_jpg/3_PHM_1508w.jpg";
import biz3j4 from "../IMG/Main/Our Business/3_PHM_jpg/3_PHM_1960w.jpg";

import biz4w from "../IMG/Main/Our Business/4_HDP.webp";
import biz4a1 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_256w.avif";
import biz4a2 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_531w.avif";
import biz4a3 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_754w.avif";
import biz4a4 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_1044w.avif";
import biz4a5 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_1296w.avif";
import biz4a6 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_1646w.avif";
import biz4a7 from "../IMG/Main/Our Business/4_HDP_bm3f2h/4_HDP_1960w.avif";
import biz4j1 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_256w.jpg";
import biz4j2 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_555w.jpg";
import biz4j3 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_725w.jpg";
import biz4j4 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_1127w.jpg";
import biz4j5 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_1255w.jpg";
import biz4j6 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_1597w.jpg";
import biz4j7 from "../IMG/Main/Our Business/4_HDP_jpg/4_HDP_1960w.jpg";

import Card1a from '../IMG/Main/Slide/Card1.avif';
import Card1a1 from '../IMG/Main/Slide/Card1_jihvad/Card1_300w.avif'
import Card1a2 from '../IMG/Main/Slide/Card1_jihvad/Card1_603w.avif'
import Card1a4 from '../IMG/Main/Slide/Card1_jihvad/Card1_970w.avif'
import Card1a7 from '../IMG/Main/Slide/Card1_jihvad/Card1_1472w.avif'
import Card1a8 from '../IMG/Main/Slide/Card1_jihvad/Card1_1631w.avif'
import Card1a9 from '../IMG/Main/Slide/Card1_jihvad/Card1_1818w.avif'
import Card1a11 from '../IMG/Main/Slide/Card1_jihvad/Card1_2171w.avif'
import Card1a12 from '../IMG/Main/Slide/Card1_jihvad/Card1_2333w.avif'
import Card1a13 from '../IMG/Main/Slide/Card1_jihvad/Card1_2593w.avif'
import Card1a14 from '../IMG/Main/Slide/Card1_jihvad/Card1_2814w.avif'
import Card1a15 from '../IMG/Main/Slide/Card1_jihvad/Card1_3001w.avif'
import Card1a16 from '../IMG/Main/Slide/Card1_jihvad/Card1_3517w.avif'
import Card1a19 from '../IMG/Main/Slide/Card1_jihvad/Card1_3840w.avif'
import Card1w from '../IMG/Main/Slide/Card1.webp';
import Card1j1 from '../IMG/Main/Slide/Card1_jpg/Card1_400w.jpg'
import Card1j3 from '../IMG/Main/Slide/Card1_jpg/Card1_957w.jpg'
import Card1j5 from '../IMG/Main/Slide/Card1_jpg/Card1_1417w.jpg'
import Card1j6 from '../IMG/Main/Slide/Card1_jpg/Card1_1729w.jpg'
import Card1j7 from '../IMG/Main/Slide/Card1_jpg/Card1_2051w.jpg'
import Card1j10 from '../IMG/Main/Slide/Card1_jpg/Card1_2541w.jpg'
import Card1j12 from '../IMG/Main/Slide/Card1_jpg/Card1_2951w.jpg'
import Card1j14 from '../IMG/Main/Slide/Card1_jpg/Card1_3245w.jpg'
import Card1j17 from '../IMG/Main/Slide/Card1_jpg/Card1_3840w.jpg'

import Card2a from '../IMG/Main/Slide/Card2.avif';
import Card2a1 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_300w.avif'
import Card2a2 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_918w.avif'
import Card2a3 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_1371w.avif'
import Card2a4 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_1662w.avif'
import Card2a5 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_1936w.avif'
import Card2a6 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_2170w.avif'
import Card2a7 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_2458w.avif'
import Card2a8 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_2738w.avif'
import Card2a9 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_3093w.avif'
import Card2a12 from '../IMG/Main/Slide/Card2_h3hmoi/Card2_3840w.avif'
import Card2w from '../IMG/Main/Slide/Card2.webp';
import Card2j1 from '../IMG/Main/Slide/Card2_jpg/Card2_400w.jpg'
import Card2j2 from '../IMG/Main/Slide/Card2_jpg/Card2_935w.jpg'
import Card2j4 from '../IMG/Main/Slide/Card2_jpg/Card2_1664w.jpg'
import Card2j5 from '../IMG/Main/Slide/Card2_jpg/Card2_1965w.jpg'
import Card2j7 from '../IMG/Main/Slide/Card2_jpg/Card2_2419w.jpg'
import Card2j9 from '../IMG/Main/Slide/Card2_jpg/Card2_2817w.jpg'
import Card2j10 from '../IMG/Main/Slide/Card2_jpg/Card2_3128w.jpg'
import Card2j11 from '../IMG/Main/Slide/Card2_jpg/Card2_3431w.jpg'
import Card2j12 from '../IMG/Main/Slide/Card2_jpg/Card2_3840w.jpg'


import { IoCaretForwardSharp, IoCaretBackSharp } from 'react-icons/io5'
import { useNavigate } from "react-router-dom";

import { useState, useRef, useEffect } from 'react';

const responsiveCards = [
  `
    ${Card1a1} 300w,
    ${Card1a2} 603w,
    ${Card1a4} 970w,
    ${Card1a7} 1472w,
    ${Card1a8} 1631w,
    ${Card1a9} 1818w,
    ${Card1a11} 2171w,
    ${Card1a12} 2333w,
    ${Card1a13} 2593w,
    ${Card1a14} 2814w,
    ${Card1a15} 3001w,
    ${Card1a16} 3517w,
    ${Card1a19} 3840w
  `,
  `
    ${Card2a1} 300w,
    ${Card2a2} 918w,
    ${Card2a3} 1371w,
    ${Card2a4} 1662w,
    ${Card2a5} 1936w,
    ${Card2a6} 2170w,
    ${Card2a7} 2458w,
    ${Card2a8} 2738w,
    ${Card2a9} 3093w,
    ${Card2a12} 3840w
  `
];

const responsiveCardsJpg = [
  `
    ${Card1j1} 400w,
    ${Card1j3} 957w,
    ${Card1j5} 1417w,
    ${Card1j6} 1729w,
    ${Card1j7} 2051w,
    ${Card1j10} 2541w,
    ${Card1j12} 2951w,
    ${Card1j14} 3245w,
    ${Card1j17} 3840w
  `,
  `
    ${Card2j1} 400w,
    ${Card2j2} 935w,
    ${Card2j4} 1664w,
    ${Card2j5} 1965w,
    ${Card2j7} 2419w,
    ${Card2j9} 2817w,
    ${Card2j10} 3128w,
    ${Card2j11} 3431w,
    ${Card2j12} 3840w
  `
]

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
      savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
      function tick() {
          savedCallback.current();
      }
      if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
      }
  }, [delay]);
}

const Main = () => {

  // 카드 이미지
  //const cardSet = [Card1, Card2];
  const cardSetw = [Card1w,Card2w];
  const cardSeta = [Card1a,Card2a];
  const cardLen = cardSeta.length;

  // 타이틀
  const titleSet = [
    `The Best Digital Healthcare Solution\n\nFor Your Business`,
    "We Provide The Best Healthcare AI\n\nSolutions"
  ];

  // 현재 그림 Index
  const [curPic, setCurPic] = useState(0);
  const router = useNavigate();
  const transitionTime = 1000;
  const transitionStyle = `transform ${transitionTime}ms ease 0s`;
  const [slideTransition, setTransition] = useState(transitionStyle);
  const [slideX, setSlideX] = useState(null);
  const [slides,setSlides] = useState([]);
  const [titles,setTitles] = useState([]);

  const [prevSlideX, setPrevSlideX] = useState(false);

  const [isSwiping, setIsSwiping] = useState(false);

  const sliderRef = useRef();

  useInterval(() => {
    handleSlide(curPic + 1)
  }, !isSwiping && !prevSlideX ? 3000 : null)

  useEffect(()=>{
    const pseudoSlides = () => {
      let addedFront = [];
      let addedLast = [];
      var idx = 0;
      while(idx<1) {
        addedLast.push(cardSeta[idx%cardLen])
        addedFront.unshift(cardSeta[cardLen - 1 - idx % cardLen])
        idx++;
      }
      return [...addedFront, ...cardSeta, ...addedLast];
    };
    const pseudoTitles = () => {
      let addedFront = [];
      let addedLast = [];
      var idx = 0;
      while(idx<1) {
        addedLast.push(titleSet[idx%cardLen])
        addedFront.unshift(titleSet[cardLen - 1 - idx % cardLen])
        idx++;
      }
      return [...addedFront, ...titleSet, ...addedLast];
    };
    setSlides(pseudoSlides());
    setTitles(pseudoTitles());

    return () => {
      setSlides(pseudoSlides());
      setTitles(pseudoTitles());
    };
  },[])

  const getItemIdx = (idx) => {
    idx -= 1;
    if(idx<0) idx+=cardLen;
    else if(idx>=cardLen) idx-=cardLen;
    return idx;
  };

  function getClientX(event) {
    return event._reactName === "onTouchStart" ? event.touches[0].clientX :
           event._reactName === "onTouchMove" || event._reactName === "onTouchEnd" ? event.changedTouches[0].clientX : event.clientX;
  }

  const replaceSlide = (idx) => {
    setTimeout(()=>{
      setTransition('');
      setCurPic(idx);
    },transitionTime)
  };

  const handleSlide = (idx) => {
    setCurPic(idx);
    if(idx - 1 < 0) {
      idx += cardLen
      replaceSlide(idx)
    }
    else if(idx - 1 >= cardLen) {
      idx -= cardLen;
      replaceSlide(idx)
    }
    setTransition(transitionStyle);
  };

  const handleSwipe = (direction) => {
    setIsSwiping(true);
    handleSlide(curPic+direction);
  };

  const handleTouchStart = (e) => {
    setPrevSlideX(prevSlideX => getClientX(e))
  };

  const handleTouchMove = (e) => {
    if (prevSlideX) {
      setSlideX(slideX => getClientX(e) - prevSlideX);
    }
  };

  const handleMouseSwipe = (e) => {
    if(slideX) {
      const currentTouchX = getClientX(e);
      if(prevSlideX>currentTouchX + 100) handleSlide(curPic+1);
      else if(prevSlideX < currentTouchX - 100) handleSlide(curPic-1);
      setSlideX(slideX=>null);
    }
    setPrevSlideX(prevSlideX=>null);
  };

  


  return (

      <div className={styles.wrapper}>
        {/* Carousel */}
        <div className={styles.cardSlide}> 

          <div className={styles.iconWrap}>
            <IoCaretBackSharp className={styles.cardIcon} onClick={() => handleSwipe(-1)}/>
          </div>

          <div className={styles.iconWrap}>
            <IoCaretForwardSharp className={styles.cardIcon} onClick={() => handleSwipe(1)}/>
          </div>

          {/* Inner */}
          <div 
            className={styles.cardBox} 
            ref={sliderRef} 
            
            onMouseOver={() => setIsSwiping(true)}
            onMouseOut={() => setIsSwiping(false)}
            style={{
              transform: `translateX(calc(${(-100 / slides.length) * (curPic)}% + ${slideX || 0}px))`,
              transition: slideTransition
          }}>
          
            
            {/* Card */}
            {slides && slides.map((v,i)=>{
              const itemIdx = getItemIdx(i);
              return(
            <div 
              className={styles.slider} 
              key={`card_${i}`}
              onMouseDown={handleTouchStart}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onMouseMove={handleTouchMove}
              onMouseUp={handleMouseSwipe}
              onTouchEnd={handleMouseSwipe}
              onMouseLeave={handleMouseSwipe}
              
            >
              <div className={styles.titleDiv}>
                <span style={{opacity: curPic===i ? 1 : 0}}>
                  {titles[itemIdx]}
                </span>
              </div>
              <picture>
                <source 
                  width="100%" 
                  height="100%" 
                  type="image/avif" 
                  srcSet={responsiveCards[itemIdx]}
                  //src={cardSetw[itemIdx]} 
                  sizes="100vw"
                />
                <source
                  width="100%"
                  height="100%"
                  type="image/jpg"
                  srcSet={responsiveCardsJpg[itemIdx]}
                  sizes="(min-width: 760px) 100vw, calc(129.55vw - 219px)"
                />
                <img width="100%" height="100%" src={cardSetw[itemIdx]} alt={`_imageHome_${i}`} />
                {/* <source width="100%" height="100%" type="image/webp" srcSet={cardSetw[itemIdx]}/>
                <img width="100%" height="100%" src={cardSet[itemIdx]} alt={`_imageHome_${i}`} /> */}
              </picture>
              
            </div>
            )})}
            
          </div>
        </div>
        
        <div className={styles.contentBox}>
          <div className={styles.subTitle2}>
            <span>Our Business</span>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.cardUnit} onClick={()=>router('/rnd')}>
              <div className={styles.cardImg}>
                <picture>
                  <source 
                    width="100%" 
                    height="100%" 
                    type="image/avif" 
                    //alt="_imageHomeBiz1"
                    srcSet={`
                      ${biz1a1} 256w,
                      ${biz1a2} 709w,
                      ${biz1a3} 1007w,
                      ${biz1a4} 1546w,
                      ${biz1a5} 1960w
                    `}
                    //src={biz1w}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <source
                    width="100%"
                    height="100%"
                    type="image/jpg"
                    srcSet={`
                      ${biz1j1} 256w,
                      ${biz1j2} 703w,
                      ${biz1j3} 1074w,
                      ${biz1j4} 1545w,
                      ${biz1j5} 1960w
                    `}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <img width="100%" height="100%" src={biz1w} alt="_imageHomeBiz1"/>
                  {/* <source width="100%" height="100%" type="image/webp" srcSet={biz1w}/> */}
                  {/* <img width="100%" height="100%" src={biz1a} alt="_imageHomeBiz1"/> */}
                </picture>
              </div>
              <div className={styles.cardDesc}>
                <span>디지털 헬스케어 연구개발</span>
                <span>Digital Healthcare R&D</span>
              </div>
            </div>
            <div className={styles.cardUnit} onClick={()=>router('/biz')}>
              <div className={styles.cardImg}>
                <picture>
                  <source 
                    width="100%" 
                    height="100%" 
                    type="image/avif" 
                    //alt="_imageHomeBiz2"
                    srcSet={`
                      ${biz2a1} 256w,
                      ${biz2a2} 908w,
                      ${biz2a3} 1574w,
                      ${biz2a4} 1960w
                    `}
                    //src={biz2w}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <source
                    width="100%"
                    height="100%"
                    type="image/jpg"
                    srcSet={`
                      ${biz2j1} 256w,
                      ${biz2j2} 857w,
                      ${biz2j3} 1594w,
                      ${biz2j4} 1960w 
                    `}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <img width="100%" height="100%" src={biz2w} alt="_imageHomeBiz2"/>
                  {/* <source width="100%" height="100%" type="image/webp" srcSet={biz2w}/>
                  <img width="100%" height="100%" src={biz2} alt="_imageHomeBiz2"/> */}
                </picture>               
              </div>  
              <div className={styles.cardDesc}>
                <span>EMR 연동형 인공지능 예측 솔루션</span>
                <span>EMR-Synced AI Prediction Solution</span>
              </div>
            </div>
            <div className={styles.cardUnit} onClick={()=>router('/biz')}>
              <div className={styles.cardImg}>
                <picture>
                  <source 
                    width="100%" 
                    height="100%" 
                    type="image/avif" 
                    //alt="_imageHomeBiz3"
                    srcSet={`
                      ${biz3a1} 256w,
                      ${biz3a2} 1013w,
                      ${biz3a3} 1515w,
                      ${biz3a4} 1960w
                    `}
                    //src={biz3w}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <source
                    width="100%"
                    height="100%"
                    type="image/jpg"
                    srcSet={`
                      ${biz3j1} 256w,
                      ${biz3j2} 863w,
                      ${biz3j3} 1508w,
                      ${biz3j4} 1960w
                    `}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <img width="100%" height="100%" src={biz3w} alt="_imageHomeBiz3"/>
                  {/* <source width="100%" height="100%" type="image/webp" srcSet={biz3w}/>
                  <img width="100%" height="100%" src={biz3} alt="_imageHomeBiz3"/> */}
                </picture>
              </div>
              <div className={styles.cardDesc}>
                <span>개인건강관리 스마트 솔루션</span>
                <span>Personal Healthcare Management</span>
              </div>
            </div>
            <div className={styles.cardUnit} onClick={()=>router('/biz')}>
              <div className={styles.cardImg}>
                <picture>
                  <source 
                    width="100%" 
                    height="100%" 
                    type="image/avif" 
                    //alt="_imageHomeBiz4"
                    srcSet={`
                      ${biz4a1} 256w,
                      ${biz4a2} 531w,
                      ${biz4a3} 754w,
                      ${biz4a4} 1044w,
                      ${biz4a5} 1296w,
                      ${biz4a6} 1646w,
                      ${biz4a7} 1960w
                    `}
                    //src={biz4w}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <source
                    width="100%"
                    height="100%"
                    type="image/jpg"
                    srcSet={`
                      ${biz4j1} 256w,
                      ${biz4j2} 555w,
                      ${biz4j3} 725w,
                      ${biz4j4} 1127w,
                      ${biz4j5} 1255w,
                      ${biz4j6} 1597w,
                      ${biz4j7} 1960w
                    `}
                    sizes="(min-width: 2360px) 20vw, (min-width: 1000px) calc(21.27vw - 43px), (min-width: 920px) 100vw, calc(100vw - 64px)"
                  />
                  <img width="100%" height="100%" src={biz4w} alt="_imageHomeBiz4"/>
                  {/* <source width="100%" height="100%" type="image/webp" srcSet={biz4w}/>
                  <img width="100%" height="100%" src={biz4} alt="_imageHomeBiz4"/> */}
                </picture>
              </div>
              <div className={styles.cardDesc}>
                <span>헬스케어 데이터 플랫폼</span>
                <span>Healthcare Data Platform</span>
              </div>
            </div>
          </div>

        </div>
      </div>
  );
};

export default Main;
