import SubTitle from "../Components/SubTitle";
import career from '../IMG/Career/career_image_1.jpg';
import ideal from '../IMG/Career/인재상_1.png';
import recruitment from '../IMG/Career/채용절차.svg.png';
import styles from './styles/About.module.scss';
import { Fragment } from 'react';

const Career = () => {

    return(
        <Fragment>
            {/* subtitle */}
            <SubTitle loc="Career"/>
            {/* 인공지능 기반 의료 서비스 전문가 */}
            <div className={styles.contentBox} style={{paddingTop:'3rem'}}>
                <div className={styles.subTitle}>
                    <span>{'인공지능 기반 의료IT서비스 전문가'}</span>
                </div>
                <div className={styles.textContent}>
                    <div className={styles.imageAndText}>
                        <span>
                        {'\n MDHi와 함께 일하고 함께 성장할 수 있는 기회에 도전하세요.'}
                        {' 내 옆자리 동료가 최고의 멘토가 될 수 있는 전문가들이 모여있는 업무 환경이 구성되어 있습니다.\n'}
                        {'MDHi는 각 조직 구성원이 가지고 있는 재능을 투자하고 유기적으로 협업하여 성과를 만들며, 인공지능 기반 의료IT 서비스의 실현을 통해 사회적 가치를 창출하는데 함께 하고 있습니다.'}
                        </span> 
                    </div>
                </div>
            </div>
            {/* 문화, 근무환경 */}
            <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>{'\n\n문화/근무환경'}</span>
                    </div>
                    <div className={styles.subTitle_half}>
                        <span>{'\n우리 삶의 성장과 자부심 그리고 행복이 함께하도록 만들어 가고 있습니다.'}</span>
                    </div>
                        <div className={styles.imageTextContent_Career}>
                            <div className={styles.textWrap}>
                                <span>
                                {/* <b>{'삶의 성장과 자부심,행복이 함께하도록 만들어 가고 있습니다.'}</b> */}
                                {'\n• 탄력적 출퇴근 시간(오전 9시 이전까지 원하는 시간에 출근, 자유롭게 8시간 이후 퇴근).\n'}
                                {'• 상호존중 조직문화를 위해 직책을 “~님“ 으로 통합하여 사용합니다.\n'}
                                {'• Refresh 휴가, 육아 휴가 장려, 야근, 회식 강요하지 않습니다. \n'}
                                {'• 최신 노트북과 27인치 모니터를 제공 합니다. \n'}
                                {'• 기술 서적 구매 비용을 지원 합니다.\n'}
                                {'• 성장을 위해 온라인 및 오프라인 강의, 세미나 비용을 지원 합니다. \n'}
                                {'• 장기근속자 포상(5년단위)을 실시합니다. \n'}
                                {'• 팀 빌딩을 위한 회식/워크샵을 지원합니다.\n'}
                                {'• 건강이 최고!! 전직원 건강검진을 제공합니다. \n'}
                                {'• 내일채움공제 가능합니다. \n'}
                                {'• 경조 휴가를 부여하고, 회사에서는 축하 또는 조의의 마음을 표하기 위하여 경조금과 경조화환을 전달합니다.\n'}
                                {'• 4대 보험 외에 단체상해 보험에 가입하여 각종 상해, 사고, 질병에 대한 부담을 최소화 할 수 있도록 보장하고 있습니다. \n'}
                                </span>
                            </div>
                            <div className={styles.imageWrap}>
                                <img src={career} alt='career' />
                            </div>
                        </div>
            </div>
            {/* 인재상 */}
            <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>{'\n\n인재상'}</span>
                    </div>
                    <div className={styles.imageContent}>
                        <img src={ideal} alt='ideal'/>
                    </div>
            </div>
            {/* 채용절차 */}
            <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>{'\n\n채용절차'}</span>
                    </div>
                    <div className={styles.imageContent}>
                        <img src={recruitment} alt='recruitment'/>
                    </div>
            </div>
        </Fragment>
    )
}

export default Career;