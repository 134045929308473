import footerLogo from "../IMG/Common/footer_logo.png";

const Footer = () => {
  return (
    <footer>
      {/* <div className="Footer">
        <div className="Footer-Container">
          <div className="Footer-Contents-wrap">
            <div className="Link-wrap">
              <div className="Line-Title">MDHi </div>
              <div> LinkIcon</div>
            </div>
          </div>

          <div className="Contact-Details">
            <div className="Title">&nbsp;</div>
            <div className="Desc-Container">
              <div className="Desc-wrap1">
                <div className="Desc1">
                  <FaMapMarkedAlt />
                  :[본사] 대전광역시 유성구 테크노1로 75(관평동 705) 한밭대학교
                  대덕산학융합캠퍼스 508호
                </div>
                <div className="Desc2">
                  <FaPhoneAlt />
                  :070-8802-0246
                </div>
                <div className="Desc3">
                  <FaMailBulk />: contact@mdhi.co.kr
                </div>
              </div>
            </div>
            <div className="CopyRight">
              Copyright © 2021 MDHi.Co., Ltd. | All Rights Reserved.
            </div>
          </div>

          <div className="Contact-Details2">
            <div className="Title">&nbsp;</div>
            <div className="Desc-Container">
              <div className="Desc-wrap2">
                <div className="Desc1">
                  <FaMapMarkedAlt />: [연구소] 수원특례시 영통구 월드컵로
                  193번길 52-3
                </div>
                <div className="Desc2">
                  <FaPhoneAlt />: 031-211-1020
                </div>
                <div className="Desc3">
                  <FaMailBulk />: contact@mdhi.co.kr
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="footer">
        <div className="footer_container">
          <div className="logo_contact_wrap">
            <div className="logo">
            <img src={footerLogo} alt="logo" width="100%"></img>
            </div>
            <div className="contact_contents_copyright_wrap">
              <div className="contents_wrap">
                <div className="company_title_desc_wrap">
                  <div className="company_title">엠디하이</div>
                  <div className="company_desc">
                  34129 대전광역시 유성구 가정로 218, 302-1-3호
                    (Tel) 070-8802-0246
                  </div>
                  
                </div>
                <div className="lab_title_desc_wrap">
                  <div className="lab_title">연구소</div>
                  <div className="lab_desc">
                  16502 수원특례시 영통구 월드컵로 193번길 52-3 
                    (Tel) 031-221-1020
                  </div>
                </div>
              </div>
              <div className="copyright">
                Copyright © 2021 MDHi.Co., Ltd. | All Rights Reserved.
              </div>
            </div>
          </div>
          <div className="icons_wrap">
          <a href="https://www.linkedin.com/company/mdhi-corp/" target='_blank' rel="noreferrer">
            <div className="icon1"></div>icon1
            </a>
            <a href="https://youtube.com" target='_blank' rel="noreferrer">
            <div className="icon2"></div>icon2
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
