import { Fragment, useState } from 'react';

import SubTitle from "../Components/SubTitle";


import Glance from '../IMG/ABout MDHi/Glance/Glance.png';
import GlanceModal from '../IMG/ABout MDHi/Glance/At a Glance003.png';
import Intro from '../IMG/ABout MDHi/Intro/Intro_2.png';
import Mission from '../IMG/ABout MDHi/Mission/Mission&Vision1.png';
import CiOne from '../IMG/ABout MDHi/CI/Pic1.jpg'
import CiTwo from '../IMG/ABout MDHi/CI/Pic2.jpg'

import styles from './styles/About.module.scss';

const Modal = (props) => {
    const { setOpenModal } = props;

    const onClick = (e) => {
        setOpenModal(false);
    };

    return(
        <div className={styles.modalWrapper} onClick={onClick}>
            <div className={styles.modalBody} onClick={(e)=>e.stopPropagation()}>
                <img src={GlanceModal} alt="_imageGlanceModal"/>
            </div>
        </div>
    );
};

const About = () => {

    const [ openModal, setOpenModal ] = useState(false);

    return(
        <Fragment>
            <SubTitle loc="About MDHi"/>
            <div id='AboutContentsWrap' className={styles.wrapper} style={{paddingTop:'3rem'}}>

                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>Introduction MDHi</span>
                    </div>

                    <div className={styles.textContent}>
                        <span>
                            국가과학기술분야 정부출연기관 <strong>ETRI</strong>와 경기남부 수도권 상급종합병원으로써 최고의 의료수준과
                            세계적 명성의 <strong>아주대학교의료원</strong> 그리고 의료기관을 주고객으로 병원정보시스템을 운영 및 관리하고
                            있는 <strong>대아정보시스템</strong>이 국가연구개발 사업의 결과물을 토대로 상용화를 위해 연구소기업 <strong>MDHi</strong>
                            가 설립되었습니다.
                        </span>
                    </div>
                </div>

                <div className={styles.imageBox}>                
                    <img src={Intro} alt="_imageIntro"/>    
                </div>
                
                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>At a Glance</span>
                    </div>

                    <div className={styles.imageTextContent}>
                        
                        <div className={styles.imageWrap}>
                            <img src={Glance} alt="_imageGlance"/>
                            <div className={styles.textBox}>
                                <span className={styles.openModal} onClick={()=>setOpenModal(!openModal)}>대표이사 약력 보기</span>
                            </div>
                        </div>
                        <div className={styles.textWrap}>
                            <span>
                                <b>{"안녕하세요.\n"}
                                {"엠디하이(주) 대표이사 정재연입니다.\n"}</b>
                                {"엠디하이는 신생 연구소기업으로 인공지능 기반 헬스케어 IT서비스를 제공하는 디지털 기업입니다.\n\n"}
                                {"4차 산업혁명시대에 헬스케어 서비스 시장의 확대와 의료데이터 규제 완화 및 AICM(AI, IoT, Cloud, BigData, Mobile) 분야의 "}
                                {"기술발달로 국내외 유수의 ICT 기업들은 혁신기술을 활용하여 헬스케어 기술융합을 통한 시장선점에 뛰어들고 있습니다.\n\n"}
                                {"엠디하이는 고객의 가치를 최우선으로 생각하며, 혁신적인 IT융합기술을 활용하여 개인 맞춤형 건강관리 서비스가 가능한 "}
                                {"Great Precision Service(GPS)를 준비하고 헬스케어 시장에서 퍼스트 무버가 되기 위해 노력하고 있습니다.\n\n"}
                                {"나아가서 인류의 건강한 삶에 기여하고자 하며, 미래의 성과를 달성하기 위해 끊임없는 연구개발을 통해 미래기술을 "}
                                {"준비하는 엠디하이의 새로운 행보를 기대해 주시기 바랍니다."}
                            </span>
                        </div>
                        
                    </div>
                </div>

                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>Mission & Vision</span>
                    </div>

                    <div className={styles.imageContent}>
                        <img src={Mission} alt="_imageMission"/>
                    </div>
                </div>

                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>CI</span>
                    </div>

                    <div className={styles.imageContent}>
                        <img src={CiOne} alt="_imageMission"/>
                        <img src={CiTwo} alt="_imageMission" />
                    </div>
                </div>

                {openModal && <Modal setOpenModal={setOpenModal}/>}

            </div>
        </Fragment>
    )
}

export default About;