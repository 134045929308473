import {Link} from 'react-router-dom'

const SubTitle = (props)=>{
    return (
        <div className="SubTitle">
            <div className="Contents-wrap">
                <div className="Contents_Subtitle">{props.loc}</div>
                <div className="Contents_GoToMain">
                    <Link to ="../">HOME&nbsp;::&nbsp;</Link> <Link to="./">{props.loc}</Link>
                    </div>
            </div>



        </div>
    )
}


export default SubTitle