import { Reset } from "styled-reset";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { Suspense } from 'react';
import { Route, Routes, Outlet, useLocation } from "react-router-dom";
import Main from "./Page/Main";
import About from "./Page/About";
import Business from "./Page/Business";
import Career from "./Page/Career";
import Contact from "./Page/Contact";
import RND from "./Page/RND";
import Error from "./Page/Error";
import { useEffect } from 'react';
import './mdhi.scss';

const Layout = () => {

  return(
    <div className="Global">
      <Header/>
        <Suspense fallback={<div>LOADING</div>}>
          <Outlet/>
        </Suspense>
      <Footer/>
    </div>
  );

};

const ScrollTop = ({children}) => {
  const location = useLocation();

  useEffect(()=>{
    window.scrollTo({top:0})
  },[location])

  return(<>{children}</>)
};

function App() {
  return (
    <>
      <Reset />
      <ScrollTop>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="/" element={<Main/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/biz" element={<Business/>} />
            <Route path="/career" element={<Career/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/rnd" element={<RND/>} />
          </Route>
          <Route path="/*" element={<Error status={404}/>} />
        </Routes>
      </ScrollTop>
 
    </>
  );
}

export default App;
