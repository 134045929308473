import React from 'react';
import { createRoot, hydrateRoot} from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import  "./mdhi.scss"

const element = document.getElementById('root')
const root = createRoot(element);

// if(element.hasChildNodes()) {
//     hydrateRoot(
//         element,
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     )
// } else {
//     root.render(
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     )
// }

// hydrateRoot(
//     element,
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>
// )

root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

