import SubTitle from "../Components/SubTitle";
import etri_logo from '../IMG/R&D/Logo_Grey.png';
import new_image from '../IMG/R&D/new_image.jpg'
import styles from './styles/About.module.scss';
import { Fragment } from 'react';

const RND = () => {

    return(
        <Fragment>
        <SubTitle loc="R&D"/>
        <div className={styles.wrapper} style={{paddingTop:'3rem'}}>
            <div className={styles.contentBox}>
                <div className={styles.subTitle}>
                    <span>Research & Development</span>
                </div>
            </div>
            <div className={styles.contentBox}>
                <div className={styles.imageTextContent}>
                    <div className={styles.textWrap}>
                        <img src = { etri_logo } alt = 'etri_logo' /><br/><br/>
                        <span>
                            {'한국전자통신연구원연구개발지원\n\n'}
                            {'심혈관질환을 위한 인공지능 주치의 기술 개발\n\n\n'}
                            {'2017-01-01~2021-12-31\n'}
                        </span>
                    </div>
                    <div className={styles.textWrap}>
                        <img src = { etri_logo } alt = 'etri_logo' /><br/><br/>
                        <span>
                            {'ICT융합산업혁신기술개발\n\n'}
                            {'최적 치료 협진 서비스를 위한 다기관 분산지능\n 강화 페더레이션 기술 개발\n\n'}
                            {'2022-01-01 ~ 2024-12-31\n'}
                        </span>
                    </div>
                    <div className={styles.textWrap}>
                        <img src = { new_image } alt = 'new_image' style={{width:'336px', height:'100px', paddingTop : '39px', paddingBottom : '38px'}}/><br/><br/>
                        <span>
                            {'산업기술혁신사업\n\n'}
                            {'영상진단 의료기기 탑재용 AI 진단 기술 개발\n(경북대학교 산학협력단)\n\n'}
                            {'2023-01-01 ~ 2024-12-31\n'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
    )
}

export default RND;